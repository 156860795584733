



























































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, Global, Props } from '@/store';
import Utils from '@/modules/Utils';
import API, { Types } from '@/modules/API';
import config from '../../../map/map.config';
import PropEditor from '@/components/PropEditor.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PropUtils from '@/modules/PropUtils';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    PropEditor,
    ConfirmDialog
  }
})
export default class DeviceDataTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;
  @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;

  // @ts-ignore
  @Prop() device;
  @Prop() projectProperties;
  @Prop() metaDeviceData;
  @Prop() cabinetDevices;
  @Prop() updateMetaDeviceFields;
  @Prop() operation_state_prop;
   
  formFieldsListHub = [];
  cabinet_data = {
    assigned_devices: 0,
    installed_count: 0,
    communicated_count: 0,
    uncommunicated_count: 0
  };
  confirmDialog = false;
  deviceData = {};
  estimated_address = false;
  loading = true;
  lengthRules = [
    (v) => !!v || this.$t('Field is required'),
    (v) => (v && v.length <= 50) || 'Max field length is 50 characters'
  ];
  optional_cabinet_id_options = [];
  devices_id_options = [];
  cabinets_id_options = [];
  search = '';
  new_cabinet_message = `I know that this ID doesn't exist in the system and I'm responsible for the consequences`;
  show_new_message = false;
  prop_utils = PropUtils;
  description_field = null;

  mounted() {
    this.loading = true;
    this.loadFields();
    this.loading = false;
  }

  loadFields() {
    const form_meta_device_hub = this.projectProperties.find((el) => el.name === 'dashboard.form_meta_device_hub');
    this.formFieldsListHub = form_meta_device_hub ? form_meta_device_hub['value']['fields'] : [];
    this.formFieldsListHub = this.formFieldsListHub.filter((field) => field.name !== 'cabinet_type');
    this.setCabinetIdOptions();
    this.deviceData = cloneDeep(this.metaDeviceData);

    if (this.formFieldsListHub) {
      const address_field = this.formFieldsListHub.find((field) => field.name === 'cabinet_address');
      if (address_field){
        this.estimated_address = !this.metaDeviceData['cabinet_address'] && this.metaDeviceData.estimated_address;
        this.deviceData['cabinet_address'] = this.metaDeviceData['cabinet_address'] || this.metaDeviceData.estimated_address || '';
      }
      this.description_field = this.formFieldsListHub.find((field) => field.name === 'description');
    }
  }

  get readonly(){
    return this.readonly_user || (this.operation_state_prop && this.operation_state_prop.cabinet_opstate === 'delivered');
  }

  setCabinetIdOptions(){
    this.devices_id_options = this.fixture_meta_device_options.cabinet_id || [];
    this.cabinets_id_options = this.cabinet_meta_device_options.cabinet_id || [];
    
    this.optional_cabinet_id_options = this.devices_id_options.filter((cabinet_id) => !this.cabinets_id_options.includes(cabinet_id));
    this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
  }

  mandatoryCheck(field_name, mandatory){
    return mandatory ? !!this.deviceData[field_name] || this.$t('Field Is Required') : true;
  }

  checkUniqueId(){
    return !this.deviceData['cabinet_id']
      ? this.$t('Field Is Required')
      : this.cabinets_id_options.includes(this.deviceData['cabinet_id']) &&
        this.metaDeviceData['cabinet_id'] !== this.deviceData['cabinet_id']
        ? this.$t(`Cabinet with same id already exists`)
        : true;
  }

  getTextfieldLabel(field){
    return field.name === 'cabinet_address'
      ? `${this.$t(`${PropUtils.getFieldLowerToUpper(field.name)}`)}${this.estimated_address && `(${this.$t('Estimated')})` || ''}${field.mandatory && '*' || ''}`
      : `${this.$t(`${PropUtils.getFieldLowerToUpper(field.name)}`)}${field.mandatory && '*' || ''}`;
  }

  @Watch('search')
  updateCabinetID(){
    this.deviceData['cabinet_id'] = this.search;
    this.show_new_message = this.checkUniqueId() === true && this.checkNewCabinetID();
  }

  checkNewCabinetID(){
    return !this.optional_cabinet_id_options.includes(this.search) && !this.cabinets_id_options.includes(this.search);
  }

  numberRules(item) {
    return item['mandatory'] ?
      [
        (v) =>  !v ? this.$t('Field is required') :
          (!isNaN(parseFloat(v)) &&
            v >= item['range']['min'] &&
            v <= item['range']['max']) ||
          `${this.$t('Number has to be between')} ${item['range']['min']} ${this.$t('and')} ${item['range']['max']}`
      ] :
      [
        (v) => !v ||
          (!isNaN(parseFloat(v)) &&
            v >= item['range']['min'] &&
            v <= item['range']['max']) ||
          `${this.$t('Number has to be between')} ${item['range']['min']} ${this.$t('and')} ${item['range']['max']}`
      ];
  }

  async setCurrentLocation() {
    const data: any = await Utils.getCurrentPosition();
    if (data && data.coords) {
      const uppercase_address = PropUtils.findMatchedUppercaseField(this.deviceData, 'address');

      try {
        const { latitude, longitude } = data.coords;
        let GEODATA;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Number(
            latitude
          )},${Number(longitude)}&key=${config.TOKEN_GOOGLE}`
        );
        if (response.ok) {
          const json = await response.json();
          if (json.error_message) {
            throw new Error(json.error_message);
          }
          GEODATA = json.results[0] && json.results[0].formatted_address;
          this.deviceData['address'] = GEODATA;
          if (uppercase_address) this.deviceData[uppercase_address] = GEODATA;
          this.$forceUpdate();
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log('error in converting address', error);
        this.deviceData['address'] = 'Unknown location';
        if (uppercase_address) this.deviceData[uppercase_address] = 'Unknown location';
      }
    }
  }

  checkFormValidation(){
    try {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.confirmDialog = true;
      }else {
        this.$notify({
          type: 'error',
          title: 'Warning',
          text: 'Please fullfill all required fields'
        });
      }
    } catch (error) {
      console.log('ERROR WHILE VALIDATE DATA:', error);
    }
  }

  updateDeviceProperty() {
    const prop = {
      name: 'meta.device',
      objectID: this.device.id,
      objectType: Types.DEVICES
    };

    if (this.formFieldsListHub.some((field) => field.name === 'cabinet_id') && typeof this.deviceData['cabinet_id'] !== 'string'){
      this.deviceData['cabinet_id'] = this.deviceData['cabinet_id'].value;
    }

    this.deviceData = PropUtils.getUpdatedUppercaseFields(this.deviceData);

    (this.$refs.propEditor as PropEditor).save(
      this.deviceData,
      prop,
      () => {
        if (this.show_new_message || !this.cabinets_id_options.includes(this.deviceData['cabinet_id'])) {
          this.updateCurrentOptions();
          this.updateSearchProperty();
          this.show_new_message = false;
        }
        const device = this.commissioned_map.get(this.device.id);

        device['meta.device'] = this.deviceData;
        this.$emit('dataChanged', this.deviceData);
        this.updateMetaDeviceFields(this.deviceData);
      },
      'All changes have been saved successfully'
    );
    this.confirmDialog = false;
  }

  updateCurrentOptions(){
    this.cabinets_id_options.push(this.deviceData['cabinet_id']);
    this.cabinets_id_options = this.cabinets_id_options.filter((cabinet_id) => cabinet_id !== this.metaDeviceData['cabinet_id']);
    this.optional_cabinet_id_options = this.devices_id_options.filter((cabinet_id) => !this.cabinets_id_options.includes(cabinet_id));
    this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
  }

  updateSearchProperty(){
    const cabinet_id_options = this.cabinet_meta_device_options.cabinet_id;
    const prev_id_index = cabinet_id_options.indexOf(this.metaDeviceData['cabinet_id']);
    cabinet_id_options.splice(prev_id_index, 1, this.deviceData['cabinet_id']);
  }

  // async runMetaDeviceOptionsService(){
  //   const apikey = await JSON.parse(localStorage.getItem('user')).apikey;
  //   const url = 'https://dashboard-api.tondo-iot.com/update_meta_device_options';
  //   const body = {
  //     company_id: this.project.company,
  //     project_id: this.project.id
  //   };

  //   API.externalAPI(url, 'POST', {'user-api-key': apikey}, body, true);
  // }
}
